import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import TopDots from "../../static/images/about-blue-dots.svg"
import YellowDot from "../../static/images/large-yellow-dot.svg"
import PinkLine from "../../static/images/pink-line.svg"


const Reviews = props => (
  <Layout>
    <Helmet>
      <title>Reviews | IBDmate</title>
    </Helmet>
    <div className="layout-reviews">
      <div className="yellow-bg">
        <div className="wrapper">
          <div className="content">
            <h1>What people say</h1>
            <p>Find out what young people and parents are saying about how IBDmate can help.</p>
          </div>
          <div className="vectors">
            <img src={TopDots} id="blue-dots" alt="" />
            <img src={YellowDot} id="yellow-dot" alt="" />
            <img src={PinkLine} id="pink-line" alt="" />
          </div>
        </div>
      </div>
      <div className="white-bg">
        <div className="wrapper">
          {props.data.reviews.review.map(content => (
            <div className="review">
              <div className="content" dangerouslySetInnerHTML={{ __html: content.frontmatter.review }} />
              <div className="author">
                <strong>{content.frontmatter.name}</strong>
                <br />{content.frontmatter.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Layout>
)

export default Reviews


export const pageQuery = graphql`
  query {
    reviews: allMarkdownRemark(
      filter:{
        fileAbsolutePath:{regex:"/(reviews)/"}
      }
      sort:{ order:ASC, fields: [frontmatter___name] }
    ){
      review: nodes {
        frontmatter {
          name
          title,
          review
        }
      }
    }
  }
`
